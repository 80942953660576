import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AsyncReturnType, HttpResponseObject } from './models/common';
import { UseQueryOptions, useQuery, UseMutationOptions, useMutation } from 'react-query';
import { UnitObject, WorkTimeRequest, WorkTimeResponse, WorkUnitRequest } from './models/work-unit';
import i18n from 'src/utils/translate/config';
import { PostWorkTimeRequest } from './models/order';

export const getWorkUnit = <TData = AxiosResponse<WorkUnitRequest[]>>(
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get('/workunit', options);
};

export const getWorkUnitQueryKey = () => [`/workunit`];

export const useGetWorkUnit = <
  TQueryFnData = AsyncReturnType<typeof getWorkUnit, AxiosResponse<WorkUnitRequest[]>>,
  TError = HttpResponseObject,
  TData = TQueryFnData
>(options?: {
  query?: UseQueryOptions<TQueryFnData, TError, TData>;
  axios?: AxiosRequestConfig;
  // depent?: any;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options || {};
  const queryKey = i18n.language ? [getWorkUnitQueryKey(), i18n.language] : getWorkUnitQueryKey();
  const query = useQuery<TQueryFnData, TError, TData>(
    queryKey,
    () => getWorkUnit<TQueryFnData>(axiosOptions),
    { ...queryOptions, cacheTime: 0 }
  );

  return { queryKey, ...query };
};

const postWorkTimeByDoctorId = <TData = AxiosResponse<WorkTimeResponse>>(
  doctorId: number,
  bodyRequest: WorkTimeRequest,
  options?: AxiosRequestConfig
): Promise<TData> => axios.post(`/user-freetime-in-month/${doctorId}`, bodyRequest, options);

const postWorkTimeByDoctorIdOnline = <TData = AxiosResponse<WorkTimeResponse>>(
  doctorId: number,
  bodyRequest: WorkTimeRequest,
  options?: AxiosRequestConfig
): Promise<TData> => axios.post(`/user-freetime-in-month-online/${doctorId}`, bodyRequest, options);

export const usePostWorkTimeByDoctorId = <
  TData = AsyncReturnType<typeof postWorkTimeByDoctorId, AxiosResponse>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { doctorId: number; bodyRequest: WorkTimeRequest }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { doctorId: number; bodyRequest: WorkTimeRequest }, TContext>(props => {
    const { doctorId, bodyRequest } = props || {};

    return postWorkTimeByDoctorId<TData>(doctorId, bodyRequest, axiosOptions);
  }, mutationOptions);
};

export const usePostWorkTimeByDoctorIdOnline = <
  TData = AsyncReturnType<typeof postWorkTimeByDoctorIdOnline, AxiosResponse>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { doctorId: number; bodyRequest: WorkTimeRequest }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { doctorId: number; bodyRequest: WorkTimeRequest }, TContext>(props => {
    const { doctorId, bodyRequest } = props || {};

    return postWorkTimeByDoctorIdOnline<TData>(doctorId, bodyRequest, axiosOptions);
  }, mutationOptions);
};

const getWorkTime = <TData = AxiosResponse<string[]>>(options?: AxiosRequestConfig): Promise<TData> => {
  return axios.get('/exam-time', options);
};

export const useGetWorkTime = <
  TData = AsyncReturnType<typeof getWorkTime, AxiosResponse<string[]>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, TContext>(() => getWorkTime<TData>(axiosOptions), mutationOptions);
};

const getWorkTimeImageAnalysation = <TData = AxiosResponse<string[]>>(
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get('/exam-time/image-analysation', options);
};

export const useGetWorkTimeImageAnalysation = <
  TData = AsyncReturnType<typeof getWorkTime, AxiosResponse<string[]>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, TContext>(
    () => getWorkTimeImageAnalysation<TData>(axiosOptions),
    mutationOptions
  );
};

export const getDrugUnit = <TData = AxiosResponse<UnitObject[]>>(
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get('/unit', options);
};

export const getDrugUnitQueryKey = () => ['/drug-unit/'];

export const useGetDrugUnit = <
  TQueryFnData = AsyncReturnType<typeof getDrugUnit, AxiosResponse<UnitObject[]>>,
  TError = HttpResponseObject,
  TData = TQueryFnData
>(options?: {
  query?: UseQueryOptions<TQueryFnData, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options || {};
  const queryKey = queryOptions?.queryKey ?? getDrugUnitQueryKey();
  const query = useQuery<TQueryFnData, TError, TData>(
    queryKey,
    () => getDrugUnit<TQueryFnData>(axiosOptions),
    queryOptions
  );

  return { queryKey, ...query };
};

export const postWorkTime = <TData = AxiosResponse<WorkTimeResponse>>(
  bodyRequest: PostWorkTimeRequest,
  options?: AxiosRequestConfig
): Promise<TData> => axios.post('/user-time-doctor', bodyRequest, options);

export const postWorkTimeOnline = <TData = AxiosResponse<WorkTimeResponse>>(
  bodyRequest: PostWorkTimeRequest,
  options?: AxiosRequestConfig
): Promise<TData> => axios.post('/user-time-doctor-online', bodyRequest, options);

import React from 'react';
import { Link } from 'react-router-dom';
import { mapModifiers } from 'src/libs/component';

type Modifiers = 'with-background' | 'with-black' | 'with-textLink' | 'none-underline' | 'button';
export interface TextLinkProps extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'className'> {
  children: React.ReactNode;
  modifiers?: Modifiers | Modifiers[];
  useExternalLink?: boolean;
  style_links?: boolean;
  size?: number;
  click?: () => void
  isButton?: boolean
}

export const TextLink: React.FC<TextLinkProps> = ({
  children,
  modifiers,
  size,
  isButton,
  style_links,
  click,
  useExternalLink,
  style,
  ...anchorProps
}) =>
  useExternalLink ? (
    <a
      target="_blank"
      rel="noopener"
      {...anchorProps}
      style={{ fontSize: size, width: style_links ? 'fit-content' : '', textAlign: style_links ? 'start' : undefined, ...style }}
      className={mapModifiers('a-text-link', modifiers)}
    >
      {children}
    </a>
  ) :
    !isButton ? <Link
      {...anchorProps
      }
      // reloadDocument
      to={anchorProps.href || ''}
      style={{ fontSize: size, ...style }}
      className={mapModifiers('a-text-link', modifiers)}
    >
      {children}
    </Link > : <button
      type='button'
      onClick={click}
      style={{ fontSize: size, ...style }}
      className={mapModifiers('a-text-link', modifiers)}
    >

      {children}
    </button>

export default {
  ALL_ORDERS_PAGE: '/all-orders/',
  DETAIL_APPLICATION_LIST_DATE_PAGE: '/applicationdatelist/',
  DETAIL_APPLICATION_LIST_PAGE: '/applicationlist/',
  DETAIL_BOOKING_EQUIPMENT_PAGE: '/infobookingequipment/',
  DETAIL_BOOKING_PAGE: '/infobooking/',
  DETAIL_BOOKING_SUCCESS_PAGE: '/bookingsuccess/',
  DETAIL_MANAGE_PATIENT_LIST_PAGE: '/managepatientlist/',
  DETAIL_SCHEDULE_MANAGEMENT_PAGE: '/schedulemanagement/',
  DIAGNOSTIC_TEST_COMPLETE_PAGE: '/diagnostictest/complete/',
  DIAGNOSTIC_TEST_CONFIRM_PAGE: '/diagnostictest/confirm/',
  DIAGNOSTIC_TEST_DOCTOR_EDIT_PAGE: '/diagnostictestdoctoredit/',
  DIAGNOSTIC_TEST_EDIT_PAGE: '/diagnostictestedit/',
  DIAGNOSTIC_TEST_PAGE: '/diagnostictest/',
  ERROR_404_PAGE: '/page-error-404/',
  ERROR_500_PAGE: '/page-error-500/',
  EVALUATE_HEALTH_PAGE: '/evaluatehealth/',
  FORGET_PASSWORD_PAGE: '/forgetpassword/',
  HEALTH_CARE_COMPLETE_PAGE: '/healthcare/complete/',
  HEALTH_CARE_CONFIRM_PAGE: '/healthcare/confirm/',
  HEALTH_CARE_DOCTOR_EDIT_PAGE: '/healthcaredoctoredit/',
  HEALTH_CARE_EDIT_PAGE: '/healthcareedit/',
  HEALTH_CARE_PAGE: '/healthcare/',
  HEALTH_MONITORING_PAGE: '/health-monitoring/',
  MEDICAL_INFORMATION_PAGE: '/medical-information/',

  HOME_PAGE: '/',
  IMAGE_ANALYSATION_COMPLETE_PAGE: '/imageanalysation/complete/',
  IMAGE_ANALYSATION_CONFIRM_PAGE: '/imageanalysation/confirm/',
  IMAGE_ANALYSATION_DOCTOR_EDIT_PAGE: '/imageanalysationedoctoredit/',
  IMAGE_ANALYSATION_EDIT_PAGE: '/imageanalysationedit/',
  IMAGE_ANALYSATION_PAGE: '/imageanalysation/',
  INFO_USER_PAGE: '/infouser/',
  LOGIN_PAGE: '/login/',
  MEDICINE_EQUIPMENT_PAGE: '/medicineequipment/',
  MEDICINE_PAGE: '/medicine/',
  ORDER_LIST_IN_MONTH_PAGE: '/order-list-in-month/',
  REGISTER_PAGE: '/register/',
  RELATIVE_MANAGEMENT_PAGE: '/relativemanagement/',
  PATIENT_INFORMATION_PAGE: '/patientinformation/',
  HEALTH_RECORD_PAGE: '/health-record/',

  ALCOHOL_ELIMINATION_TIME_PAGE: '/alcohol-elimination-time/',
} as const;

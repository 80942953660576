import React, { useEffect, useRef, useState } from 'react';
import { mapModifiers } from 'src/libs/component';
import DatePicker, { Calendar, DateObject } from 'react-multi-date-picker';
import dayjs from 'dayjs';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { useTranslation } from 'react-i18next';
import { Button } from '../button';
import i18n from 'src/utils/translate/config';
import { getHolidays, Holiday } from 'src/api/holiday';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

const gregorian_vi_lowercase = {
  name: 'vi_lowercase',
  months: [
    ['Tháng 1', '1'],
    ['Tháng 2', '2'],
    ['Tháng 3', '3'],
    ['Tháng 4', '4'],
    ['Tháng 5', '5'],
    ['Tháng 6', '6'],
    ['Tháng 7', '7'],
    ['Tháng 8', '8'],
    ['Tháng 9', '9'],
    ['Tháng 10', '10'],
    ['Tháng 11', '11'],
    ['Tháng 12', '12'],
  ],
  weekDays: [
    ['Thứ Bảy', 'Bảy'],
    ['Chủ Nhật', 'CN'],
    ['Thứ Hai', 'Hai'],
    ['Thứ Ba', 'Ba'],
    ['Thứ Tư', 'Tư'],
    ['Thứ Năm', 'Năm'],
    ['Thứ Sáu', 'Sáu'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
};

export default gregorian_vi_lowercase;

export const gregorian_en_lowercase = {
  name: 'en_lowercase',
  months: [
    ['Jan', '1'],
    ['Feb', '2'],
    ['Mar', '3'],
    ['Apr', '4'],
    ['May', '5'],
    ['Jun', '6'],
    ['Jul', '7'],
    ['Aug', '8'],
    ['Sep', '9'],
    ['Oct', '10'],
    ['Nov', '11'],
    ['Dec', '12'],
  ],
  weekDays: [
    ['Saturday', 'Sat'],
    ['Sunday', 'Sun'],
    ['Monday', 'Mon'],
    ['Tuesday', 'Tue'],
    ['Wednesday', 'Wed'],
    ['Thursday', 'Thu'],
    ['Friday', 'Fri'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
};

export interface DateInputProps {
  name?: string;
  value?: string;
  noMargin?: boolean;
  onChange?: (e: DateObject | DateObject[] | null | '') => void;
  content?: string;
  checkAvailableDates?: boolean;
  availableDates?: string[];
  errorMessage?: string;
  required?: boolean;
  isCalendar?: boolean;
  minDate?: string | number | Date | DateObject | undefined;
  maxDate?: string | number | Date | DateObject | undefined;
  disabled?: boolean;
  placeholder?: string;
  handleMonthChange?: (date: DateObject) => void;
  withTimePicker?: boolean;
  clearBtn?: boolean;
  clear?: () => void;
  ref?: React.Ref<HTMLInputElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refCtrl?: (r: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isError?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isHolidayCheck?: boolean;  // Thêm prop này

}

export const DateInput: React.FC<DateInputProps> = React.forwardRef(
  (
    {
      name,
      value,
      onChange,
      content,
      errorMessage,
      availableDates,
      required,
      isCalendar,
      minDate,
      maxDate,
      disabled,
      clearBtn,
      noMargin,
      placeholder,
      checkAvailableDates,
      withTimePicker,
      handleMonthChange,
      clear,
      isError,
      refCtrl,
      isHolidayCheck,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [locale, setLocale] = useState(gregorian_vi_lowercase);

    const [holidays, setHolidays] = useState<Holiday[]>([]);

    useEffect(() => {
      if (isHolidayCheck) {
        const fetchHolidays = async () => {
          try {
            const data = await getHolidays();
            setHolidays(data);
          } catch (error) {
            console.error('failed fetch holidays', error);
          }
        };
        fetchHolidays();
      }
    }, [isHolidayCheck]);


    useEffect(() => {
      if (i18n.language == 'vi') {
        setLocale(gregorian_vi_lowercase);
      } else if (i18n.language == 'en') {
        setLocale(gregorian_en_lowercase);
      }
    }, [i18n.language]);

    const dateTimepickerRef = useRef<Document>(null);
    useEffect(() => {
      const ref = dateTimepickerRef.current;
      if (ref !== undefined) {
        const inputRef = ref?.getElementsByTagName('input')[0];
        refCtrl !== undefined && refCtrl(inputRef);
      }
    }, [refCtrl, isError]);

    return isCalendar ? (
      <div>
        <Calendar />
      </div>
    ) : (
      <div
        style={{ margin: noMargin ? '0' : '' }}
        className={mapModifiers('a-date-input', errorMessage && 'error')}
      >
        {value && value != '' && disabled != true && clearBtn !== true && (
          <p className="a-date-input__clear" onClick={() => onChange !== undefined && onChange('')}>
            X
          </p>
        )}
        <div className="wrapper" id="wrapper">
          <DatePicker
            ref={dateTimepickerRef}
            portal
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            weekStartDayIndex={1}
            format={withTimePicker ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'}
            minDate={minDate}
            maxDate={maxDate}
            editable={false}
            locale={locale}
            disabled={disabled}
            onOpenPickNewDate={false}
            buttons={true}
            mapDays={({ date }) => {
              const dateFormated = dayjs(date.toDate()).format('YYYY/MM/DD');

              const isHoliday = holidays?.some(holiday => {
                const holidayStart = dayjs(holiday.startDate).startOf('day');
                const holidayEnd = dayjs(holiday.endDate).endOf('day');
                const currentDate = dayjs(date.toDate()).startOf('day');
                return currentDate.isBetween(holidayStart, holidayEnd, null, '[]');
              });

              return {
                disabled: isHoliday || (checkAvailableDates && !availableDates?.includes(dateFormated))
              };
            }}

            onMonthChange={handleMonthChange}
            plugins={withTimePicker ? [<TimePicker key={0} position="right" hideSeconds />] : undefined}
          />
          {value && clear && (
            <Button
              modifiers="login"
              onClick={() => {
                clear();
              }}
            >
              Clear
            </Button>
          )}
        </div>
        <div className="a-date-input__label">
          {content && <label>{content}</label>}
          {required ? <span className="a-date-input__tag">{t('common.mandatory')}</span> : null}
        </div>
        {errorMessage && <p className="a-date-input__error-message">{t(errorMessage)}</p>}
      </div>
    );
  }
);
DateInput.displayName = 'DateInput';

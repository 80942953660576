import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';


export interface Holiday {
    id: number;
    startDate: string;
    endDate: string;
    description: string | undefined; 
 }

export const getHolidays = async (): Promise<Holiday[]> => {
    try {
      const response = await axios.get<Holiday[]>('/holidays');
      return response.data;
    } catch (error) {
      console.error('Error fetching holidays:', error);
      throw error;
    }
};  
import React, { useCallback, useEffect, useState } from 'react';

import { Heading } from 'src/components/atoms/heading';
import { Image } from 'src/components/atoms/image';
import { TextLink } from 'src/components/atoms/text-link';

import logo from 'src/assets/images/logo.png';

import { Button } from 'src/components/atoms/button';
import { useLocation, useNavigate } from 'react-router-dom';

import { MenuContext, MenuContextItem } from 'src/components/atoms/menu-context';
import { loadFromCookies, removeFromCookies, removeSessionStorage } from 'src/libs/utils';

import PATHES from 'src/constants/pathes';
import { DOCTOR_ROLE_ID, STORAGE_KEYS } from 'src/constants/constants';
import { useGetInfoUserSuccessLogin, usePostLogOut } from 'src/api/user';
import { Loading } from 'src/components/atoms/loading';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';

import { removeUserInfo, setUserInfo } from 'src/redux/features/login-slice';
import { Notification, NotificationItem } from 'src/components/molecules/notification';
import { useGetDashBoard } from 'src/api/dashboard';
import { NotifyToast, Toaster } from 'src/components/atoms/action-toast';
import { ConfirmAlert } from '../confirm-alert';
import { persistor } from 'src/redux/store';
import { SelectLanguage } from 'src/components/atoms/select-language';
import { useTranslation } from 'react-i18next';

import { setReset as ResetHealthCare } from 'src/redux/features/health-care-slice';
import { setReset as ResetDiagnosticTest } from 'src/redux/features/diagnostic-test-slice';
import { setReset as ResetImageAnalysation } from 'src/redux/features/image-analysation-slice';
import { setClear } from 'src/redux/features/patient-information-slice';

interface HeaderTopProps {
  showLogo?: boolean;
}

export const HeaderTop: React.FC<HeaderTopProps> = ({ showLogo = true }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userInfo } = useAppSelector(state => state.authSlice);
  const fullName = userInfo?.fullName;
  const userId = userInfo?.id;
  const accessToken = loadFromCookies('access_token', true);
  const [invitationNumber, setInvitationNumber] = useState(0);
  const [isOpenedSubmitModal, setIsOpenedSubmitModal] = useState(false);
  const [showLanguage, setShowLanguage] = useState(true);
  const { t } = useTranslation();
  const location = useLocation();
  const getDashBoard = useGetDashBoard({
    mutation: {
      useErrorBoundary: false,
      onSuccess: ({
        data: {
          data: { countInvites },
        },
      }) => {
        setInvitationNumber(countInvites);
      },
      onError: () => {
        NotifyToast(<Toaster>{t('MESSAGE_CALL_API_FAIL')}</Toaster>, 'error');
      },
    },
  });

  const handleGetDashBoard = useCallback(async (userId: number) => {
    if (getDashBoard.isLoading) return;

    getDashBoard.mutateAsync({ userId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userInfo?.userId) handleGetDashBoard(userInfo.userId);
  }, [handleGetDashBoard, userInfo]);

  const getInfoUserSuccessLogin = useGetInfoUserSuccessLogin({
    mutation: {
      useErrorBoundary: false,
      onSuccess: ({ data: { data } }) => {
        const userInformation = {
          ...userInfo,
          ...data,
          dateOfBirth: data.dateOfBirthday,
        };

        dispatch(setUserInfo(userInformation));
      },
    },
  });

  const handleGetInfoUserSuccessLogin = useCallback(
    async (params: number) => {
      if (getInfoUserSuccessLogin.isLoading) return;

      await getInfoUserSuccessLogin.mutateAsync({ params });
    },
    [getInfoUserSuccessLogin]
  );

  useEffect(() => {
    // const userId = localStorage.getItem('RHC_userId');
    if (userId) {
      const fetchData = async () => await handleGetInfoUserSuccessLogin(+userId);
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!accessToken) {
      dispatch(removeUserInfo());
      removeSessionStorage(STORAGE_KEYS.USER_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const resetData = () => {
    dispatch(setClear());
    dispatch(setUserInfo({}));
    removeFromCookies('access_token');
    persistor.purge();
    setIsOpenedSubmitModal(false);
    navigate(PATHES.HOME_PAGE);
  };

  const postLogOut = usePostLogOut({
    mutation: {
      useErrorBoundary: false,
      onSuccess: () => resetData(),
      onError: () => resetData(),
    },
  });

  const handlePostLogOut = useCallback(
    async () => {
      if (postLogOut.isLoading) {
        return;
      }

      await postLogOut.mutateAsync();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postLogOut.isLoading, postLogOut.isSuccess]
  );

  const resetDataRedux = () => {
    //health-care-slice
    dispatch(ResetHealthCare());
    //diagnostic-test-slice
    dispatch(ResetDiagnosticTest());
    //image-analysisation-slice
    dispatch(ResetImageAnalysation());
  };
  useEffect(() => {
    const pathHideLanguage = [
      '/healthcare/confirm/',
      '/healthcare/complete/',
      '/diagnostictest/confirm/',
      '/diagnostictest/complete/',
      '/imageanalysation/confirm/',
      '/imageanalysation/complete/',
    ];
    if (pathHideLanguage.includes(location.pathname)) {
      setShowLanguage(true); //namnh: pending disable language in these screen.
    } else {
      setShowLanguage(true);
    }
  }, [location]);
  return (
    <>
      <header className="o-header-top">
        <div className="o-header-top__content container">
          {fullName ? (
            <div className="o-header-top__user">
              <Notification hasNotification={invitationNumber > 0}>
                {invitationNumber > 0 && (
                  <NotificationItem>
                    {`${t('home.you-have')} ${invitationNumber} ${t('home.tracking-request-unanswered')}.`}
                    <TextLink href={`${PATHES['RELATIVE_MANAGEMENT_PAGE']}?type=watched`}>
                      {t('home.check-now')}
                    </TextLink>
                  </NotificationItem>
                )}
              </Notification>
              <MenuContext title={fullName} avatarImg={userInfo.urlImage}>
                <MenuContextItem href={PATHES['INFO_USER_PAGE']}>
                  <span className="o-header-top__index">I.</span>
                  {String(t('menu.personal-information'))}
                </MenuContextItem>
                <MenuContextItem href={PATHES['RELATIVE_MANAGEMENT_PAGE']}>
                  <span className="o-header-top__index">II.</span>
                  {String(t('menu.list-of-patients-or-relatives'))}
                </MenuContextItem>
                <MenuContextItem href={PATHES['PATIENT_INFORMATION_PAGE']}>
                  <span className="o-header-top__index">III.</span>
                  {String(t('menu.patient-or-relative-information'))}
                </MenuContextItem>
                <MenuContextItem href={PATHES['HEALTH_MONITORING_PAGE']}>
                  <span className="o-header-top__index">IV.</span>
                  {String(t('menu.health-tracking'))}
                </MenuContextItem>
                <MenuContextItem href={PATHES['DETAIL_APPLICATION_LIST_PAGE']}>
                  <span className="o-header-top__index">V.</span>
                  {String(t('menu.manage-registration-schedule'))}
                </MenuContextItem>
                {userInfo?.jobId === DOCTOR_ROLE_ID && (
                  <MenuContextItem href={PATHES['DETAIL_SCHEDULE_MANAGEMENT_PAGE']}>
                    <span className="o-header-top__index">VI.</span>
                    {String(t('menu.doctors-schedule'))}
                  </MenuContextItem>
                )}
              </MenuContext>

              <span className="o-header-top__span"> | </span>
              <Button modifiers="link" onClick={() => setIsOpenedSubmitModal(true)}>
                {t('exit')}
              </Button>
              {showLanguage && (
                <>
                  <span className="o-header-top__span"> | </span>
                  <div className="o-header-top__language">
                    <SelectLanguage theme="light" />
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="o-header-top__user">
              <TextLink useExternalLink={false} href={PATHES['LOGIN_PAGE']} modifiers="with-textLink">
                {t('signup.login')}
              </TextLink>
              <span className="o-header-top__span"> | </span>
              <TextLink useExternalLink={false} href={PATHES['REGISTER_PAGE']} modifiers="with-textLink">
                {t('signup.register')}
              </TextLink>
              <span className="o-header-top__span"> | </span>
              <div className="o-header-top__language">
                <SelectLanguage theme="light" />
              </div>
            </div>
          )}
        </div>
        {showLogo && (
          <div className="o-header-top__link container">
            <TextLink href={PATHES['HOME_PAGE']} modifiers="none-underline" onClick={resetDataRedux}>
              <div className="o-header-top__logo">
                <Image url={logo} />
                <Heading level="h1">Royal Healthcare</Heading>
              </div>
            </TextLink>
          </div>
        )}
      </header>
      {isOpenedSubmitModal && (
        <ConfirmAlert
          handleCloseRequest={() => setIsOpenedSubmitModal(false)}
          title={t('alert.title-sign-out')}
          cancelButton={
            <Button modifiers="delete" type="button" onClick={() => setIsOpenedSubmitModal(false)}>
              {t('alert.deny')}
            </Button>
          }
          submitButton={
            <Button modifiers="login" type="button" onClick={handlePostLogOut}>
              {t('alert.confirm')}
            </Button>
          }
        >
          {t('alert.messages')}
        </ConfirmAlert>
      )}
      <Loading open={getInfoUserSuccessLogin.isLoading || getDashBoard.isLoading || postLogOut.isLoading} />
    </>
  );
};
